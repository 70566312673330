@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
// app global css
@import '~mapbox-gl/dist/mapbox-gl.css'
// @import '~mapbox-gl-controls/theme.css'

@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700')
@import url('fonts/icon/style.css');

html
  overflow-y: scroll

body
  font-family: 'Merriweather Sans'
  color: $default
  background-color: $grey-2

h1, h2, h3, h4, h5, h6
  text-transform: uppercase
  font-weight: bold;
  margin: 2rem 0 0.8rem

a:not(.q-btn)
  text-decoration: none
  color: $default
  :hover
    text-decoration: underline

categories = {
  agora:    $agora
  default:  $default
  users:    $users
  tasks:    $tasks
  reseau:   $reseau
  per:      $per
  comite:   $comite
  divergence: $divergence
  process: $process
}

for category, value in categories
  .text-{category}
    color: value

  .bg-{category}
    color: white
    background-color: value

  for num in (1..5)
    $hueValue = darken(value, num / 10)

    .text-{category}-light-{num}
      color: lighten(value, num * 10)

    .bg-{category}-light-{num}
      color: white
      background-color: rgba(red($hueValue), green($hueValue), blue($hueValue), 1 - num / 10)


.title
  letter-spacing: 2px

.q-table
  color: $default

.q-table__card
  background-color: inherit

.q-table th,
.q-item__label--header
  font-weight: 600
  color: $default

@import '~mapbox-gl/dist/mapbox-gl.css';
@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700");
@import url("fonts/icon/style.css");
html {
  overflow-y: scroll;
}
body {
  font-family: 'Merriweather Sans';
  color: #616161;
  background-color: #f5f5f5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: bold;
  margin: 2rem 0 0.8rem;
}
a:not(.q-btn) {
  text-decoration: none;
  color: #616161;
}
a:not(.q-btn) :hover {
  text-decoration: underline;
}
.text-agora {
  color: #1479a5;
}
.bg-agora {
  color: #fff;
  background-color: #1479a5;
}
.text-agora-light-1 {
  color: #1a9ad2;
}
.bg-agora-light-1 {
  color: #fff;
  background-color: rgba(20,121,165,0.9);
}
.text-agora-light-2 {
  color: #38b2e7;
}
.bg-agora-light-2 {
  color: #fff;
  background-color: rgba(20,120,164,0.8);
}
.text-agora-light-3 {
  color: #66c3ec;
}
.bg-agora-light-3 {
  color: #fff;
  background-color: rgba(20,120,164,0.7);
}
.text-agora-light-4 {
  color: #93d5f2;
}
.bg-agora-light-4 {
  color: #fff;
  background-color: rgba(20,120,163,0.6);
}
.text-agora-light-5 {
  color: #c1e7f7;
}
.bg-agora-light-5 {
  color: #fff;
  background-color: rgba(20,119,163,0.5);
}
.text-default {
  color: #616161;
}
.bg-default {
  color: #fff;
  background-color: #616161;
}
.text-default-light-1 {
  color: #7b7b7b;
}
.bg-default-light-1 {
  color: #fff;
  background-color: rgba(97,97,97,0.9);
}
.text-default-light-2 {
  color: #949494;
}
.bg-default-light-2 {
  color: #fff;
  background-color: rgba(96,96,96,0.8);
}
.text-default-light-3 {
  color: #adadad;
}
.bg-default-light-3 {
  color: #fff;
  background-color: rgba(96,96,96,0.7);
}
.text-default-light-4 {
  color: #c7c7c7;
}
.bg-default-light-4 {
  color: #fff;
  background-color: rgba(96,96,96,0.6);
}
.text-default-light-5 {
  color: #e1e1e1;
}
.bg-default-light-5 {
  color: #fff;
  background-color: rgba(96,96,96,0.5);
}
.text-users {
  color: #1f9bd3;
}
.bg-users {
  color: #fff;
  background-color: #1f9bd3;
}
.text-users-light-1 {
  color: #42b1e3;
}
.bg-users-light-1 {
  color: #fff;
  background-color: rgba(31,155,211,0.9);
}
.text-users-light-2 {
  color: #6ec3ea;
}
.bg-users-light-2 {
  color: #fff;
  background-color: rgba(31,154,210,0.8);
}
.text-users-light-3 {
  color: #9bd6f0;
}
.bg-users-light-3 {
  color: #fff;
  background-color: rgba(31,154,210,0.7);
}
.text-users-light-4 {
  color: #c7e8f7;
}
.bg-users-light-4 {
  color: #fff;
  background-color: rgba(31,154,209,0.6);
}
.text-users-light-5 {
  color: #f4fafd;
}
.bg-users-light-5 {
  color: #fff;
  background-color: rgba(31,153,209,0.5);
}
.text-tasks {
  color: #1f9bd3;
}
.bg-tasks {
  color: #fff;
  background-color: #1f9bd3;
}
.text-tasks-light-1 {
  color: #42b1e3;
}
.bg-tasks-light-1 {
  color: #fff;
  background-color: rgba(31,155,211,0.9);
}
.text-tasks-light-2 {
  color: #6ec3ea;
}
.bg-tasks-light-2 {
  color: #fff;
  background-color: rgba(31,154,210,0.8);
}
.text-tasks-light-3 {
  color: #9bd6f0;
}
.bg-tasks-light-3 {
  color: #fff;
  background-color: rgba(31,154,210,0.7);
}
.text-tasks-light-4 {
  color: #c7e8f7;
}
.bg-tasks-light-4 {
  color: #fff;
  background-color: rgba(31,154,209,0.6);
}
.text-tasks-light-5 {
  color: #f4fafd;
}
.bg-tasks-light-5 {
  color: #fff;
  background-color: rgba(31,153,209,0.5);
}
.text-reseau {
  color: #8d74b8;
}
.bg-reseau {
  color: #fff;
  background-color: #8d74b8;
}
.text-reseau-light-1 {
  color: #a996c9;
}
.bg-reseau-light-1 {
  color: #fff;
  background-color: rgba(141,116,184,0.9);
}
.text-reseau-light-2 {
  color: #c4b8da;
}
.bg-reseau-light-2 {
  color: #fff;
  background-color: rgba(140,115,184,0.8);
}
.text-reseau-light-3 {
  color: #e0d9ec;
}
.bg-reseau-light-3 {
  color: #fff;
  background-color: rgba(140,115,183,0.7);
}
.text-reseau-light-4 {
  color: #fcfbfd;
}
.bg-reseau-light-4 {
  color: #fff;
  background-color: rgba(140,115,183,0.6);
}
.text-reseau-light-5 {
  color: #fff;
}
.bg-reseau-light-5 {
  color: #fff;
  background-color: rgba(140,114,183,0.5);
}
.text-per {
  color: #92b451;
}
.bg-per {
  color: #fff;
  background-color: #92b451;
}
.text-per-light-1 {
  color: #a8c375;
}
.bg-per-light-1 {
  color: #fff;
  background-color: rgba(146,180,81,0.9);
}
.text-per-light-2 {
  color: #bfd398;
}
.bg-per-light-2 {
  color: #fff;
  background-color: rgba(146,180,80,0.8);
}
.text-per-light-3 {
  color: #d5e2bc;
}
.bg-per-light-3 {
  color: #fff;
  background-color: rgba(145,180,80,0.7);
}
.text-per-light-4 {
  color: #ebf1e0;
}
.bg-per-light-4 {
  color: #fff;
  background-color: rgba(145,179,80,0.6);
}
.text-per-light-5 {
  color: #fff;
}
.bg-per-light-5 {
  color: #fff;
  background-color: rgba(145,179,79,0.5);
}
.text-comite {
  color: #408e98;
}
.bg-comite {
  color: #fff;
  background-color: #408e98;
}
.text-comite-light-1 {
  color: #54acb7;
}
.bg-comite-light-1 {
  color: #fff;
  background-color: rgba(64,142,152,0.9);
}
.text-comite-light-2 {
  color: #78bdc6;
}
.bg-comite-light-2 {
  color: #fff;
  background-color: rgba(64,141,151,0.8);
}
.text-comite-light-3 {
  color: #9ccfd5;
}
.bg-comite-light-3 {
  color: #fff;
  background-color: rgba(64,141,151,0.7);
}
.text-comite-light-4 {
  color: #c0e0e4;
}
.bg-comite-light-4 {
  color: #fff;
  background-color: rgba(63,141,151,0.6);
}
.text-comite-light-5 {
  color: #e4f2f3;
}
.bg-comite-light-5 {
  color: #fff;
  background-color: rgba(63,140,150,0.5);
}
.text-divergence {
  color: #249ad2;
}
.bg-divergence {
  color: #fff;
  background-color: #249ad2;
}
.text-divergence-light-1 {
  color: #49afe0;
}
.bg-divergence-light-1 {
  color: #fff;
  background-color: rgba(36,154,210,0.9);
}
.text-divergence-light-2 {
  color: #75c2e7;
}
.bg-divergence-light-2 {
  color: #fff;
  background-color: rgba(36,153,209,0.8);
}
.text-divergence-light-3 {
  color: #a0d5ef;
}
.bg-divergence-light-3 {
  color: #fff;
  background-color: rgba(36,153,209,0.7);
}
.text-divergence-light-4 {
  color: #cce9f6;
}
.bg-divergence-light-4 {
  color: #fff;
  background-color: rgba(36,153,208,0.6);
}
.text-divergence-light-5 {
  color: #f7fcfe;
}
.bg-divergence-light-5 {
  color: #fff;
  background-color: rgba(36,152,208,0.5);
}
.text-process {
  color: #e48360;
}
.bg-process {
  color: #fff;
  background-color: #e48360;
}
.text-process-light-1 {
  color: #eba58c;
}
.bg-process-light-1 {
  color: #fff;
  background-color: rgba(228,131,96,0.9);
}
.text-process-light-2 {
  color: #f3c7b7;
}
.bg-process-light-2 {
  color: #fff;
  background-color: rgba(228,130,95,0.8);
}
.text-process-light-3 {
  color: #fae9e3;
}
.bg-process-light-3 {
  color: #fff;
  background-color: rgba(228,130,95,0.7);
}
.text-process-light-4 {
  color: #fff;
}
.bg-process-light-4 {
  color: #fff;
  background-color: rgba(228,130,94,0.6);
}
.text-process-light-5 {
  color: #fff;
}
.bg-process-light-5 {
  color: #fff;
  background-color: rgba(228,129,94,0.5);
}
.title {
  letter-spacing: 2px;
}
.q-table {
  color: #616161;
}
.q-table__card {
  background-color: inherit;
}
.q-table th,
.q-item__label--header {
  font-weight: 600;
  color: #616161;
}
/*# sourceMappingURL=src/css/app.css.map */
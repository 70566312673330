.wrapper {
  position: relative;
}
.content {
  position: relative;
  z-index: 100;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}
.title,
.section-title {
  font-size: 1.5rem;
  font-weight: 600;
}
.title {
  color: #424242;
  line-height: 1.6rem;
}
.q-breadcrumbs {
  font-size: 0.8rem;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/components/pages/PageHeader.css.map */